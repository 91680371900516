// // src/components/Transactions.js

// import React, { useState, useEffect } from 'react';
// import '../styles/Transactions.css';
// import { FiDownload, FiSearch } from 'react-icons/fi'; // Import icons

// const Transactions = () => {
//   // **State Variables**
//   const [transactions] = useState([]); // Holds all transactions data
//   const [activeTab, setActiveTab] = useState('All'); // Current active tab
//   const [filteredTransactions, setFilteredTransactions] = useState([]); // Transactions filtered by tab and search
//   const [currentPage, setCurrentPage] = useState(1); // Current page number for pagination
//   const transactionsPerPage = 10; // Number of transactions per page
//   const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering

//   /**
//    * useEffect Hook
//    *
//    * This hook runs when the component mounts.
//    * Backend Developer: Replace the code inside this hook with an API call to fetch transactions data.
//    */
//   useEffect(() => {
//     // TODO: Fetch transactions data from the backend API and update the 'transactions' state.
//     // Example:
//     // fetch('/api/transactions')
//     //   .then(response => response.json())
//     //   .then(data => {
//     //     setTransactions(data);
//     //     setFilteredTransactions(data);
//     //   })
//     //   .catch(error => {
//     //     console.error('Error fetching transactions:', error);
//     //     // Handle error state if needed
//     //   });

//     // For now, we'll initialize 'filteredTransactions' with an empty array
//     setFilteredTransactions([]);
//   }, []);

//   // **Handle Tab Click**
//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//     filterTransactions(searchTerm, tab);
//   };

//   // **Handle Search Input**
//   const handleSearchChange = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     filterTransactions(term, activeTab);
//   };

//   // **Filter Transactions**
//   const filterTransactions = (term, tab) => {
//     let filtered = transactions;

//     // Filter by tab (transaction status)
//     if (tab !== 'All') {
//       filtered = filtered.filter((txn) => txn.status === tab);
//     }

//     // Filter by search term
//     if (term) {
//       filtered = filtered.filter(
//         (txn) =>
//           txn.id.toLowerCase().includes(term.toLowerCase()) ||
//           txn.customerName.toLowerCase().includes(term.toLowerCase()) ||
//           txn.customerEmail.toLowerCase().includes(term.toLowerCase()) ||
//           txn.customerPhone.toLowerCase().includes(term.toLowerCase())
//       );
//     }

//     setFilteredTransactions(filtered);
//     setCurrentPage(1); // Reset to first page when filters change
//   };

//   // **Pagination Logic**
//   const indexOfLastTransaction = currentPage * transactionsPerPage;
//   const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
//   const currentTransactions = filteredTransactions.slice(
//     indexOfFirstTransaction,
//     indexOfLastTransaction
//   );
//   const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

//   // **Handle Page Change**
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   // **Handle Download Transactions**
//   const handleDownload = () => {
//     // TODO: Implement actual download logic to export transactions data
//     alert('Download functionality to be implemented.');
//   };

//   return (
//     <div className="transactions-content">
//       {/* Header Section */}
//       <header className="transactions-header">
//         <div className="transactions-title">
//           <h2>Transactions</h2>
//         </div>
//         <div className="date-selector">
//           <label htmlFor="start-date" className="visually-hidden">
//             Start Date
//           </label>
//           <input
//             type="date"
//             id="start-date"
//             aria-label="Start Date"
//             // TODO: Implement date selection functionality
//           />
//           <span> to </span>
//           <label htmlFor="end-date" className="visually-hidden">
//             End Date
//           </label>
//           <input
//             type="date"
//             id="end-date"
//             aria-label="End Date"
//             // TODO: Implement date selection functionality
//           />
//           {/* Backend Developer: Implement date filtering functionality based on selected dates */}
//         </div>
//       </header>

//       {/* Action Bar */}
//       <div className="transactions-action-bar">
//         {/* Tabs */}
//         <div className="transactions-tabs">
//           {['All', 'Success', 'Pending', 'Failed'].map((tab) => (
//             <button
//               key={tab}
//               className={`tab-button ${activeTab === tab ? 'active' : ''}`}
//               onClick={() => handleTabClick(tab)}
//             >
//               {tab}
//             </button>
//           ))}
//         </div>

//         {/* Search and Download */}
//         <div className="transactions-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search transactions"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <button className="download-button" onClick={handleDownload}>
//             <FiDownload className="download-icon" />
//             Download
//           </button>
//         </div>
//       </div>

//       {/* Transactions Table */}
//       <div className="transactions-table-container">
//         <div className="transactions-table-wrapper">
//           <table className="transactions-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Transaction Id</th>
//                 <th>Amount</th>
//                 <th>Customer Name</th>
//                 <th>Customer Email</th>
//                 <th>Customer Phone</th>
//                 <th>Status</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentTransactions.length > 0 ? (
//                 currentTransactions.map((txn) => (
//                   <tr key={txn.id}>
//                     <td>{txn.date}</td>
//                     <td>{txn.id}</td>
//                     <td>₹{txn.amount}</td>
//                     <td>{txn.customerName}</td>
//                     <td>{txn.customerEmail}</td>
//                     <td>{txn.customerPhone}</td>
//                     <td>
//                       <span className={`status ${txn.status.toLowerCase()}`}>
//                         {txn.status}
//                       </span>
//                     </td>
//                     <td>
//                       {/* Placeholder for actions */}
//                       <button className="action-button">View</button>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="8" className="no-transactions">
//                     <div className="no-transactions-message">
//                       <p>No transactions found.</p>
//                     </div>
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredTransactions.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Transactions;



// import React, { useState, useEffect } from 'react';
// import '../styles/Transactions.css';
// import { FiDownload, FiSearch } from 'react-icons/fi'; 

// const Transactions = () => {
//   const [transactions, setTransactions] = useState([]); // Holds all transactions data
//   const [filteredTransactions, setFilteredTransactions] = useState([]); // Filtered transactions
//   const [activeTab, setActiveTab] = useState('All'); // Current active tab
//   const [currentPage, setCurrentPage] = useState(1); // Current page number for pagination
//   const transactionsPerPage = 10; // Number of transactions per page
//   const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering

//   const merchantId = localStorage.getItem('merchantId'); // Get merchantId from localStorage

//   // **Fetch transactions from the backend API**
//   const fetchTransactions = async () => {
//     try {
//       const response = await fetch(`http://127.0.0.1:8058/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//         setFilteredTransactions(data.transactions); // Initialize with all transactions
//       } else {
//         console.error('Error fetching transactions:', data.message);
//       }
//     } catch (error) {
//       console.error('Error fetching transactions:', error);
//     }
//   };

//   // **Polling to fetch data at regular intervals**
//   useEffect(() => {
//     // Fetch transactions when component mounts
//     fetchTransactions();

//     // Poll the backend every 5 seconds to get the latest transactions
//     const intervalId = setInterval(fetchTransactions, 5000);

//     // Cleanup the interval on component unmount
//     return () => clearInterval(intervalId);
//   }, []);

//   // **Filter Transactions by Tab (status) and Search Term**
//   const filterTransactions = (term, tab) => {
//     let filtered = transactions;

//     // Filter by tab (transaction status)
//     if (tab !== 'All') {
//       filtered = filtered.filter((txn) => txn.status === tab);
//     }

//     // Filter by search term
//     if (term) {
//       filtered = filtered.filter(
//         (txn) =>
//           txn.transaction_id.toLowerCase().includes(term.toLowerCase()) ||
//           txn.cust_name.toLowerCase().includes(term.toLowerCase()) ||
//           txn.cust_email.toLowerCase().includes(term.toLowerCase()) ||
//           txn.cust_phone.toLowerCase().includes(term.toLowerCase())
//       );
//     }

//     setFilteredTransactions(filtered);
//     setCurrentPage(1); // Reset to first page when filters change
//   };

//   // **Handle Tab Click**
//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//     filterTransactions(searchTerm, tab);
//   };

//   // **Handle Search Input**
//   const handleSearchChange = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     filterTransactions(term, activeTab);
//   };

//   // **Pagination Logic**
//   const indexOfLastTransaction = currentPage * transactionsPerPage;
//   const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
//   const currentTransactions = filteredTransactions.slice(
//     indexOfFirstTransaction,
//     indexOfLastTransaction
//   );
//   const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

//   // **Handle Page Change**
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   // **Handle Download Transactions**
//   const handleDownload = () => {
//     alert('Download functionality to be implemented.');
//   };

//   return (
//     <div className="transactions-content">
//       {/* Header Section */}
//       <header className="transactions-header">
//         <div className="transactions-title">
//           <h2>Transactions</h2>
//         </div>
//         <div className="date-selector">
//           <input type="date" id="start-date" aria-label="Start Date" />
//           <span> to </span>
//           <input type="date" id="end-date" aria-label="End Date" />
//         </div>
//       </header>

//       {/* Action Bar */}
//       <div className="transactions-action-bar">
//         {/* Tabs */}
//         <div className="transactions-tabs">
//           {['All', 'Success', 'Pending', 'Failed'].map((tab) => (
//             <button
//               key={tab}
//               className={`tab-button ${activeTab === tab ? 'active' : ''}`}
//               onClick={() => handleTabClick(tab)}
//             >
//               {tab}
//             </button>
//           ))}
//         </div>

//         {/* Search and Download */}
//         <div className="transactions-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search transactions"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <button className="download-button" onClick={handleDownload}>
//             <FiDownload className="download-icon" />
//             Download
//           </button>
//         </div>
//       </div>

//       {/* Transactions Table */}
//       <div className="transactions-table-container">
//         <div className="transactions-table-wrapper">
//           <table className="transactions-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Transaction Id</th>
//                 <th>Amount</th>
//                 <th>Customer Name</th>
//                 <th>Customer Email</th>
//                 <th>Customer Phone</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentTransactions.length > 0 ? (
//                 currentTransactions.map((txn) => (
//                   <tr key={txn.transaction_id}>
//                     <td>{new Date(txn.created_at).toLocaleString()}</td>
//                     <td>{txn.transaction_id}</td>
//                     <td>{txn.amount}</td>
//                     <td>{txn.cust_name}</td>
//                     <td>{txn.cust_email}</td>
//                     <td>{txn.cust_phone}</td>
//                     <td>
//                       <span className={`status ${txn.status.toLowerCase()}`}>
//                         {txn.status}
//                       </span>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="7" className="no-transactions">
//                     No transactions found.
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredTransactions.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Transactions;


// import React, { useState, useEffect } from 'react';
// import '../styles/Transactions.css'; // Existing styles for the component
// import Modal from 'react-modal'; // Import react-modal for modal functionality
// import { FiDownload, FiSearch, FiX } from 'react-icons/fi'; // Import icons including the close icon
// import { CSVLink } from 'react-csv'; // For CSV download

// // Set the app element for accessibility
// Modal.setAppElement('#root');

// const Transactions = () => {
//   const [transactions, setTransactions] = useState([]); // Holds all transactions data
//   const [filteredTransactions, setFilteredTransactions] = useState([]); // Filtered transactions
//   const [activeTab, setActiveTab] = useState('All'); // Current active tab (All, Success, Pending, Failed)
//   const [currentPage, setCurrentPage] = useState(1); // Current page number for pagination
//   const transactionsPerPage = 10; // Number of transactions per page
//   const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering
//   const [startDate, setStartDate] = useState(''); // Start date for filtering
//   const [endDate, setEndDate] = useState(''); // End date for filtering
//   const [selectedTransaction, setSelectedTransaction] = useState(null); // Holds the selected transaction for viewing
//   const [modalIsOpen, setModalIsOpen] = useState(false); // Controls modal visibility

//   const merchantId = localStorage.getItem('merchantId'); // Get merchantId from localStorage

//   // **Fetch transactions from the backend API**
//   const fetchTransactions = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//         setFilteredTransactions(data.transactions); // Initialize with all transactions
//       } else {
//         console.error('Error fetching transactions:', data.message);
//       }
//     } catch (error) {
//       console.error('Error fetching transactions:', error);
//     }
//   };

//   // **Polling to fetch data at regular intervals**
//   useEffect(() => {
//     fetchTransactions();  // Fetch transactions when component mounts
//     const intervalId = setInterval(fetchTransactions, 5000);  // Poll the backend every 5 seconds
//     return () => clearInterval(intervalId);  // Cleanup the interval on component unmount
//   }, []);

//   // **Open Modal with Transaction Details**
//   const openModal = (transaction) => {
//     setSelectedTransaction(transaction); // Set the selected transaction
//     setModalIsOpen(true); // Open the modal
//   };

//   // **Close Modal**
//   const closeModal = () => {
//     setSelectedTransaction(null); // Clear the selected transaction
//     setModalIsOpen(false); // Close the modal
//   };

//   // **Filter Transactions by Status, Search Term, and Date Range**
//   const filterTransactions = () => {
//     let filtered = transactions;

//     // Filter by active tab (status)
//     if (activeTab !== 'All') {
//       filtered = filtered.filter((txn) => txn.status.toLowerCase() === activeTab.toLowerCase());
//     }

//     // Filter by search term
//     if (searchTerm) {
//       filtered = filtered.filter(
//         (txn) =>
//           txn.transaction_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.cust_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.cust_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.cust_phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.payment_type.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     }

//     // Filter by date range
//     if (startDate && endDate) {
//       filtered = filtered.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//     }

//     setFilteredTransactions(filtered);
//     setCurrentPage(1); // Reset pagination
//   };

//   useEffect(() => {
//     filterTransactions(); // Call the filter function whenever filters change
//   }, [activeTab, searchTerm, startDate, endDate, transactions]);

//   // **Handle Page Change**
//   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//   // **Pagination Logic**
//   const indexOfLastTransaction = currentPage * transactionsPerPage;
//   const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
//   const currentTransactions = filteredTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);
//   const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

//   // **Download CSV Data**
//   const csvHeaders = [
//     { label: 'Date & Time', key: 'created_at' },
//     { label: 'Transaction ID', key: 'transaction_id' },
//     { label: 'Amount', key: 'amount' },
//     { label: 'Currency', key: 'currency' },
//     { label: 'Customer Name', key: 'cust_name' },
//     { label: 'Customer Email', key: 'cust_email' },
//     { label: 'Customer Phone', key: 'cust_phone' },
//     { label: 'Payment Type', key: 'payment_type' },
//     { label: 'Status', key: 'status' },
//   ];

//   return (
//     <div className="transactions-content">
//       {/* Filter and Action Bar */}
//       <div className="transactions-action-bar">
//         {/* Tabs for Status Filter */}
//         <div className="transactions-tabs">
//           {['All', 'Success', 'Pending', 'Failed'].map((tab) => (
//             <button
//               key={tab}
//               className={`tab-button ${activeTab === tab ? 'active' : ''}`}
//               onClick={() => setActiveTab(tab)}
//             >
//               {tab}
//             </button>
//           ))}
//         </div>

//         {/* Search and Date Filter */}
//         <div className="transactions-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search transactions"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <div className="date-filter">
//             <input
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               placeholder="Start Date"
//             />
//             <span> to </span>
//             <input
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               placeholder="End Date"
//             />
//           </div>
//           <CSVLink data={filteredTransactions} headers={csvHeaders} filename="transactions.csv">
//             <button className="download-button">
//               <FiDownload className="download-icon" /> Download CSV
//             </button>
//           </CSVLink>
//         </div>
//       </div>

//       {/* Transactions Table */}
//       <div className="transactions-table-container">
//         <div className="transactions-table-wrapper">
//           <table className="transactions-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Transaction ID</th>
//                 <th>Amount</th>
//                 <th>Currency</th>
//                 <th>Customer Name</th>
//                 <th>Customer Email</th>
//                 <th>Customer Phone</th>
//                 <th>Payment Type</th>
//                 <th>Status</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentTransactions.length > 0 ? (
//                 currentTransactions.map((txn) => (
//                   <tr key={txn.transaction_id}>
//                     <td>{new Date(txn.created_at).toLocaleString()}</td>
//                     <td>{txn.transaction_id}</td>
//                     <td>{txn.amount}</td>
//                     <td>{txn.currency}</td>
//                     <td>{txn.cust_name}</td>
//                     <td>{txn.cust_email}</td>
//                     <td>{txn.cust_phone}</td>
//                     <td>{txn.payment_type}</td>
//                     <td>
//                       <span className={`status ${txn.status.toLowerCase()}`}>
//                         {txn.status}
//                       </span>
//                     </td>
//                     <td>
//                       <button className="action-button" onClick={() => openModal(txn)}>View</button> {/* View button triggers modal */}
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="10" className="no-transactions">No transactions found.</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredTransactions.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>

//       {/* Modal to View Transaction Details */}
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         contentLabel="Transaction Details"
//         className="custom-modal" // Custom class for modal styling
//         overlayClassName="custom-modal-overlay" // Custom class for modal overlay styling
//       >
//         <div className="modal-header">
//           <h2>Transaction Details</h2>
//           <button onClick={closeModal} className="modal-close-btn">
//             <FiX size={24} /> {/* Close icon */}
//           </button>
//         </div>
//         {selectedTransaction && (
//           <div className="modal-content">
//             <p><strong>Transaction ID:</strong> {selectedTransaction.transaction_id}</p>
//             <p><strong>Amount:</strong> {selectedTransaction.amount}</p>
//             <p><strong>Currency:</strong> {selectedTransaction.currency}</p>
//             <p><strong>Customer Name:</strong> {selectedTransaction.cust_name}</p>
//             <p><strong>Customer Email:</strong> {selectedTransaction.cust_email}</p>
//             <p><strong>Customer Phone:</strong> {selectedTransaction.cust_phone}</p>
//             <p><strong>City:</strong> {selectedTransaction.cust_city}</p>
//             <p><strong>Membership Duration:</strong> {selectedTransaction.membership_duration} months</p>
//             <p><strong>Payment Type:</strong> {selectedTransaction.payment_type}</p>
//             <p><strong>Status:</strong> {selectedTransaction.status}</p>
//           </div>
//         )}
//       </Modal>
//     </div>
//   );
// };

// export default Transactions;



// import React, { useState, useEffect } from 'react';
// import '../styles/Transactions.css'; // Existing styles for the component
// import Modal from 'react-modal'; // Import react-modal for modal functionality
// import { FiDownload, FiSearch, FiX } from 'react-icons/fi'; // Import icons including the close icon
// import { CSVLink } from 'react-csv'; // For CSV download

// // Set the app element for accessibility
// Modal.setAppElement('#root');

// const Transactions = () => {
//   const [transactions, setTransactions] = useState([]); // Holds all transactions data
//   const [filteredTransactions, setFilteredTransactions] = useState([]); // Filtered transactions
//   const [activeTab, setActiveTab] = useState('All'); // Current active tab (All, Success, Pending, Failed)
//   const [currentPage, setCurrentPage] = useState(1); // Current page number for pagination
//   const transactionsPerPage = 10; // Number of transactions per page
//   const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering
//   const [startDate, setStartDate] = useState(''); // Start date for filtering
//   const [endDate, setEndDate] = useState(''); // End date for filtering
//   const [selectedTransaction, setSelectedTransaction] = useState(null); // Holds the selected transaction for viewing
//   const [modalIsOpen, setModalIsOpen] = useState(false); // Controls modal visibility

//   const merchantId = localStorage.getItem('merchantId'); // Get merchantId from localStorage

//   // **Fetch transactions from the backend API**
//   const fetchTransactions = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//       } else {
//         console.error('Error fetching transactions:', data.message);
//       }
//     } catch (error) {
//       console.error('Error fetching transactions:', error);
//     }
//   };

//   // **Polling to fetch data at regular intervals**
//   useEffect(() => {
//     fetchTransactions(); // Fetch transactions when component mounts
//     const intervalId = setInterval(fetchTransactions, 5000); // Poll the backend every 5 seconds
//     return () => clearInterval(intervalId); // Cleanup the interval on component unmount
//   }, []);

//   // **Filter Transactions by Status, Search Term, and Date Range**
//   const filterTransactions = () => {
//     let filtered = transactions;

//     // Filter by active tab (status)
//     if (activeTab !== 'All') {
//       filtered = filtered.filter((txn) => txn.status.toLowerCase() === activeTab.toLowerCase());
//     }

//     // Filter by search term
//     if (searchTerm) {
//       filtered = filtered.filter(
//         (txn) =>
//           txn.transaction_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.cust_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.cust_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.cust_phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.payment_type.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     }

//     // Filter by date range
//     if (startDate && endDate) {
//       filtered = filtered.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//     }

//     setFilteredTransactions(filtered);
//     // Removed setCurrentPage(1) from here
//   };

//   // **Effect for filter changes**
//   useEffect(() => {
//     filterTransactions();
//     setCurrentPage(1); // Reset to page 1 when filters change
//   }, [activeTab, searchTerm, startDate, endDate]);

//   // **Effect for transactions data update**
//   useEffect(() => {
//     filterTransactions(); // Update filtered transactions when data changes
//     // Do not reset currentPage here
//   }, [transactions]);

//   // **Handle Page Change**
//   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//   // **Pagination Logic**
//   const indexOfLastTransaction = currentPage * transactionsPerPage;
//   const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
//   const currentTransactions = filteredTransactions.slice(
//     indexOfFirstTransaction,
//     indexOfLastTransaction
//   );
//   const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

//   // **Open Modal with Transaction Details**
//   const openModal = (transaction) => {
//     setSelectedTransaction(transaction); // Set the selected transaction
//     setModalIsOpen(true); // Open the modal
//   };

//   // **Close Modal**
//   const closeModal = () => {
//     setSelectedTransaction(null); // Clear the selected transaction
//     setModalIsOpen(false); // Close the modal
//   };

//   // **Download CSV Data**
//   const csvHeaders = [
//     { label: 'Date & Time', key: 'created_at' },
//     { label: 'Transaction ID', key: 'transaction_id' },
//     { label: 'Amount', key: 'amount' },
//     { label: 'Currency', key: 'currency' },
//     { label: 'Customer Name', key: 'cust_name' },
//     { label: 'Customer Email', key: 'cust_email' },
//     { label: 'Customer Phone', key: 'cust_phone' },
//     { label: 'Payment Type', key: 'payment_type' },
//     { label: 'Status', key: 'status' },
//   ];

//   return (
//     <div className="transactions-content">
//       {/* Filter and Action Bar */}
//       <div className="transactions-action-bar">
//         {/* Tabs for Status Filter */}
//         <div className="transactions-tabs">
//           {['All', 'Success', 'Pending', 'Failed'].map((tab) => (
//             <button
//               key={tab}
//               className={`tab-button ${activeTab === tab ? 'active' : ''}`}
//               onClick={() => setActiveTab(tab)}
//             >
//               {tab}
//             </button>
//           ))}
//         </div>

//         {/* Search and Date Filter */}
//         <div className="transactions-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search transactions"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <div className="date-filter">
//             <input
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               placeholder="Start Date"
//             />
//             <span> to </span>
//             <input
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               placeholder="End Date"
//             />
//           </div>
//           <CSVLink
//             data={filteredTransactions}
//             headers={csvHeaders}
//             filename="transactions.csv"
//           >
//             <button className="download-button">
//               <FiDownload className="download-icon" /> Download CSV
//             </button>
//           </CSVLink>
//         </div>
//       </div>

//       {/* Transactions Table */}
//       <div className="transactions-table-container">
//         <div className="transactions-table-wrapper">
//           <table className="transactions-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Transaction ID</th>
//                 <th>Amount</th>
//                 <th>Currency</th>
//                 <th>Customer Name</th>
//                 <th>Customer Email</th>
//                 <th>Customer Phone</th>
//                 <th>Payment Type</th>
//                 <th>Status</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentTransactions.length > 0 ? (
//                 currentTransactions.map((txn) => (
//                   <tr key={txn.transaction_id}>
//                     <td>{new Date(txn.created_at).toLocaleString()}</td>
//                     <td>{txn.transaction_id}</td>
//                     <td>{txn.amount}</td>
//                     <td>{txn.currency}</td>
//                     <td>{txn.cust_name}</td>
//                     <td>{txn.cust_email}</td>
//                     <td>{txn.cust_phone}</td>
//                     <td>{txn.payment_type}</td>
//                     <td>
//                       <span className={`status ${txn.status.toLowerCase()}`}>
//                         {txn.status}
//                       </span>
//                     </td>
//                     <td>
//                       <button
//                         className="action-button"
//                         onClick={() => openModal(txn)}
//                       >
//                         View
//                       </button>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="10" className="no-transactions">
//                     No transactions found.
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredTransactions.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>

//       {/* Modal to View Transaction Details */}
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         contentLabel="Transaction Details"
//         className="custom-modal" // Custom class for modal styling
//         overlayClassName="custom-modal-overlay" // Custom class for modal overlay styling
//       >
//         <div className="modal-header">
//           <h2>Transaction Details</h2>
//           <button onClick={closeModal} className="modal-close-btn">
//             <FiX size={24} /> {/* Close icon */}
//           </button>
//         </div>
//         {selectedTransaction && (
//           <div className="modal-content">
//             <p>
//               <strong>Transaction ID:</strong> {selectedTransaction.transaction_id}
//             </p>
//             <p>
//               <strong>Amount:</strong> {selectedTransaction.amount}
//             </p>
//             <p>
//               <strong>Currency:</strong> {selectedTransaction.currency}
//             </p>
//             <p>
//               <strong>Customer Name:</strong> {selectedTransaction.cust_name}
//             </p>
//             <p>
//               <strong>Customer Email:</strong> {selectedTransaction.cust_email}
//             </p>
//             <p>
//               <strong>Customer Phone:</strong> {selectedTransaction.cust_phone}
//             </p>
//             <p>
//               <strong>City:</strong> {selectedTransaction.cust_city}
//             </p>
//             <p>
//               <strong>Membership Duration:</strong>{' '}
//               {selectedTransaction.membership_duration} months
//             </p>
//             <p>
//               <strong>Payment Type:</strong> {selectedTransaction.payment_type}
//             </p>
//             <p>
//               <strong>Status:</strong> {selectedTransaction.status}
//             </p>
//           </div>
//         )}
//       </Modal>
//     </div>
//   );
// };

// export default Transactions;
 


// import React, { useState, useEffect } from 'react';
// import '../styles/Transactions.css';
// import { format } from 'date-fns';
// import { toast } from 'react-toastify';

// const Transactions = () => {
//   const [transactions, setTransactions] = useState([]);
//   const [merchantName, setMerchantName] = useState('');
//   const [selectedTransaction, setSelectedTransaction] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [availableBalance, setAvailableBalance] = useState(0);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredTransactions, setFilteredTransactions] = useState([]);

//   const merchantId = localStorage.getItem('merchantId');

//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     setMerchantName(storedMerchantName || 'Merchant');

//     fetchTransactions();
//     fetchBalances();
//   }, []);

//   const fetchTransactions = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//         setFilteredTransactions(data.transactions);
//       } else {
//         toast.error('Failed to fetch transactions');
//       }
//     } catch (error) {
//       toast.error('An error occurred while fetching transactions');
//     }
//   };

//   const fetchBalances = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/balances/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setAvailableBalance(parseFloat(data.available_balance));
//         localStorage.setItem('availableBalance', data.available_balance);
//       }
//     } catch (error) {
//       console.error('Error fetching balances:', error);
//     }
//   };

//   const openModal = (transaction) => {
//     setSelectedTransaction(transaction);
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setSelectedTransaction(null);
//     setShowModal(false);
//   };

//   const handleRefund = async () => {
//     if (!selectedTransaction) return;

//     try {
//       const response = await fetch('https://payin.payinfy.com/api/process-refund/', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           transaction_id: selectedTransaction.transaction_id,
//           merchant_id: merchantId,
//         }),
//       });

//       const data = await response.json();
//       if (data.status === 'Success') {
//         toast.success('Refund processed successfully');
//         await fetchBalances();
//         await fetchTransactions();
//         closeModal();
//       } else {
//         toast.error(data.message || 'Failed to process refund');
//       }
//     } catch (error) {
//       toast.error('An error occurred while processing refund');
//     }
//   };

//   const handleChargeback = async () => {
//     if (!selectedTransaction) return;

//     try {
//       const response = await fetch('https://payin.payinfy.com/api/process-chargeback/', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           transaction_id: selectedTransaction.transaction_id,
//           merchant_id: merchantId,
//         }),
//       });

//       const data = await response.json();
//       if (data.status === 'Success') {
//         toast.success('Chargeback processed successfully');
//         await fetchBalances();
//         await fetchTransactions();
//         closeModal();
//       } else {
//         toast.error(data.message || 'Failed to process chargeback');
//       }
//     } catch (error) {
//       toast.error('An error occurred while processing chargeback');
//     }
//   };

//   const handleSearch = (e) => {
//     const term = e.target.value.toLowerCase();
//     setSearchTerm(term);

//     const filtered = transactions.filter((txn) =>
//       txn.cust_name.toLowerCase().includes(term) ||
//       txn.status.toLowerCase().includes(term) ||
//       txn.transaction_id.toLowerCase().includes(term)
//     );

//     setFilteredTransactions(filtered);
//   };

//   return (
//     <div className="transactions-container">
//       <h2>{merchantName}'s Transactions</h2>
//       <div className="transactions-header">
//         <input
//           type="text"
//           placeholder="Search by Customer Name, Status, or Transaction ID"
//           value={searchTerm}
//           onChange={handleSearch}
//         />
//         <div className="balance-info">
//           <span>Available Balance:</span>
//           <span className="balance-amount">₹ {availableBalance.toFixed(2)}</span>
//         </div>
//       </div>
//       <table className="transactions-table">
//         <thead>
//           <tr>
//             <th>Date & Time</th>
//             <th>Customer Name</th>
//             <th>Amount</th>
//             <th>Payment Type</th>
//             <th>Status</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredTransactions.length > 0 ? (
//             filteredTransactions.map((transaction) => (
//               <tr
//                 key={transaction.transaction_id}
//                 onClick={() => openModal(transaction)}
//                 className="transaction-row"
//               >
//                 <td>{format(new Date(transaction.created_at), 'dd/MM/yyyy HH:mm:ss')}</td>
//                 <td>{transaction.cust_name}</td>
//                 <td>{transaction.amount}</td>
//                 <td>{transaction.payment_type}</td>
//                 <td className={`status ${transaction.status.toLowerCase()}`}>
//                   {transaction.status}
//                 </td>
//               </tr>
//             ))
//           ) : (
//             <tr>
//               <td colSpan="5" className="no-records">
//                 No transactions found.
//               </td>
//             </tr>
//           )}
//         </tbody>
//       </table>

//       {/* Transaction Details Modal */}
//       {showModal && selectedTransaction && (
//         <div className="modal-overlay" onClick={closeModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <h3>Transaction Details</h3>
//             <p>
//               <strong>Transaction ID:</strong> {selectedTransaction.transaction_id}
//             </p>
//             <p>
//               <strong>Customer Name:</strong> {selectedTransaction.cust_name}
//             </p>
//             <p>
//               <strong>Amount:</strong> ₹ {selectedTransaction.amount}
//             </p>
//             <p>
//               <strong>Payment Type:</strong> {selectedTransaction.payment_type}
//             </p>
//             <p>
//               <strong>Status:</strong>{' '}
//               <span className={`status ${selectedTransaction.status.toLowerCase()}`}>
//                 {selectedTransaction.status}
//               </span>
//             </p>
//             {/* Add Refund and Chargeback buttons for successful transactions */}
//             {selectedTransaction.status === 'SUCCESS' && (
//               <div className="modal-buttons">
//                 <button className="refund-button" onClick={handleRefund}>
//                   Refund
//                 </button>
//                 <button className="chargeback-button" onClick={handleChargeback}>
//                   Chargeback
//                 </button>
//               </div>
//             )}
//             <button className="close-button" onClick={closeModal}>
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Transactions;



// import React, { useState, useEffect } from 'react';
// import '../styles/Transactions.css'; // Existing styles for the component
// import Modal from 'react-modal'; // Import react-modal for modal functionality
// import { FiDownload, FiSearch, FiX } from 'react-icons/fi'; // Import icons including the close icon
// import { CSVLink } from 'react-csv'; // For CSV download
// import { toast } from 'react-toastify'; // For notifications

// // Set the app element for accessibility
// Modal.setAppElement('#root');

// const Transactions = () => {
//   const [transactions, setTransactions] = useState([]); // Holds all transactions data
//   const [filteredTransactions, setFilteredTransactions] = useState([]); // Filtered transactions
//   const [activeTab, setActiveTab] = useState('All'); // Current active tab (All, Success, Pending, Failed)
//   const [currentPage, setCurrentPage] = useState(1); // Current page number for pagination
//   const transactionsPerPage = 10; // Number of transactions per page
//   const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering
//   const [startDate, setStartDate] = useState(''); // Start date for filtering
//   const [endDate, setEndDate] = useState(''); // End date for filtering
//   const [selectedTransaction, setSelectedTransaction] = useState(null); // Holds the selected transaction for viewing
//   const [modalIsOpen, setModalIsOpen] = useState(false); // Controls modal visibility

//   const merchantId = localStorage.getItem('merchantId'); // Get merchantId from localStorage

//   // **Fetch transactions from the backend API**
//   const fetchTransactions = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//       } else {
//         console.error('Error fetching transactions:', data.message);
//       }
//     } catch (error) {
//       console.error('Error fetching transactions:', error);
//     }
//   };

//   // **Polling to fetch data at regular intervals**
//   useEffect(() => {
//     fetchTransactions(); // Fetch transactions when component mounts
//     const intervalId = setInterval(fetchTransactions, 5000); // Poll the backend every 5 seconds
//     return () => clearInterval(intervalId); // Cleanup the interval on component unmount
//   }, []);

//   // **Filter Transactions by Status, Search Term, and Date Range**
//   const filterTransactions = () => {
//     let filtered = transactions;

//     // Filter by active tab (status)
//     if (activeTab !== 'All') {
//       filtered = filtered.filter((txn) => txn.status.toLowerCase() === activeTab.toLowerCase());
//     }

//     // Filter by search term
//     if (searchTerm) {
//       filtered = filtered.filter(
//         (txn) =>
//           txn.transaction_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.cust_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.cust_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.cust_phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.payment_type.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     }

//     // Filter by date range
//     if (startDate && endDate) {
//       filtered = filtered.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//     }

//     setFilteredTransactions(filtered);
//     // Removed setCurrentPage(1) from here
//   };

//   // **Effect for filter changes**
//   useEffect(() => {
//     filterTransactions();
//     setCurrentPage(1); // Reset to page 1 when filters change
//   }, [activeTab, searchTerm, startDate, endDate]);

//   // **Effect for transactions data update**
//   useEffect(() => {
//     filterTransactions(); // Update filtered transactions when data changes
//     // Do not reset currentPage here
//   }, [transactions]);

//   // **Handle Page Change**
//   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//   // **Pagination Logic**
//   const indexOfLastTransaction = currentPage * transactionsPerPage;
//   const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
//   const currentTransactions = filteredTransactions.slice(
//     indexOfFirstTransaction,
//     indexOfLastTransaction
//   );
//   const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

//   // **Open Modal with Transaction Details**
//   const openModal = (transaction) => {
//     setSelectedTransaction(transaction); // Set the selected transaction
//     setModalIsOpen(true); // Open the modal
//   };

//   // **Close Modal**
//   const closeModal = () => {
//     setSelectedTransaction(null); // Clear the selected transaction
//     setModalIsOpen(false); // Close the modal
//   };

//   // **Handle Refund**
//   const handleRefund = async () => {
//     if (!selectedTransaction) return;

//     try {
//       const response = await fetch('https://payin.payinfy.com/api/process-refund/', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           transaction_id: selectedTransaction.transaction_id,
//           merchant_id: merchantId,
//         }),
//       });

//       const data = await response.json();
//       if (data.status === 'Success') {
//         toast.success('Refund processed successfully');
//         fetchTransactions(); // Refresh transactions
//         closeModal();
//       } else {
//         toast.error(data.message || 'Failed to process refund');
//       }
//     } catch (error) {
//       toast.error('An error occurred while processing refund');
//     }
//   };

//   // **Handle Chargeback**
//   const handleChargeback = async () => {
//     if (!selectedTransaction) return;

//     try {
//       const response = await fetch('https://payin.payinfy.com/api/process-chargeback/', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           transaction_id: selectedTransaction.transaction_id,
//           merchant_id: merchantId,
//         }),
//       });

//       const data = await response.json();
//       if (data.status === 'Success') {
//         toast.success('Chargeback processed successfully');
//         fetchTransactions(); // Refresh transactions
//         closeModal();
//       } else {
//         toast.error(data.message || 'Failed to process chargeback');
//       }
//     } catch (error) {
//       toast.error('An error occurred while processing chargeback');
//     }
//   };

//   // **Download CSV Data**
//   const csvHeaders = [
//     { label: 'Date & Time', key: 'created_at' },
//     { label: 'Transaction ID', key: 'transaction_id' },
//     { label: 'Amount', key: 'amount' },
//     { label: 'Currency', key: 'currency' },
//     { label: 'Customer Name', key: 'cust_name' },
//     { label: 'Customer Email', key: 'cust_email' },
//     { label: 'Customer Phone', key: 'cust_phone' },
//     { label: 'Payment Type', key: 'payment_type' },
//     { label: 'Status', key: 'status' },
//   ];

//   return (
//     <div className="transactions-content">
//       {/* Filter and Action Bar */}
//       <div className="transactions-action-bar">
//         {/* Tabs for Status Filter */}
//         <div className="transactions-tabs">
//           {['All', 'Success', 'Pending', 'Failed'].map((tab) => (
//             <button
//               key={tab}
//               className={`tab-button ${activeTab === tab ? 'active' : ''}`}
//               onClick={() => setActiveTab(tab)}
//             >
//               {tab}
//             </button>
//           ))}
//         </div>

//         {/* Search and Date Filter */}
//         <div className="transactions-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search transactions"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <div className="date-filter">
//             <input
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               placeholder="Start Date"
//             />
//             <span> to </span>
//             <input
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               placeholder="End Date"
//             />
//           </div>
//           <CSVLink
//             data={filteredTransactions}
//             headers={csvHeaders}
//             filename="transactions.csv"
//           >
//             <button className="download-button">
//               <FiDownload className="download-icon" /> Download CSV
//             </button>
//           </CSVLink>
//         </div>
//       </div>

//       {/* Transactions Table */}
//       <div className="transactions-table-container">
//         <div className="transactions-table-wrapper">
//           <table className="transactions-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Transaction ID</th>
//                 <th>Amount</th>
//                 <th>Currency</th>
//                 <th>Customer Name</th>
//                 <th>Customer Email</th>
//                 <th>Customer Phone</th>
//                 <th>Payment Type</th>
//                 <th>Status</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentTransactions.length > 0 ? (
//                 currentTransactions.map((txn) => (
//                   <tr key={txn.transaction_id}>
//                     <td>{new Date(txn.created_at).toLocaleString()}</td>
//                     <td>{txn.transaction_id}</td>
//                     <td>{txn.amount}</td>
//                     <td>{txn.currency}</td>
//                     <td>{txn.cust_name}</td>
//                     <td>{txn.cust_email}</td>
//                     <td>{txn.cust_phone}</td>
//                     <td>{txn.payment_type}</td>
//                     <td>
//                       <span className={`status ${txn.status.toLowerCase()}`}>
//                         {txn.status}
//                       </span>
//                     </td>
//                     <td>
//                       <button
//                         className="action-button"
//                         onClick={() => openModal(txn)}
//                       >
//                         View
//                       </button>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="10" className="no-transactions">
//                     No transactions found.
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredTransactions.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>

//       {/* Modal to View Transaction Details */}
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         contentLabel="Transaction Details"
//         className="custom-modal" // Custom class for modal styling
//         overlayClassName="custom-modal-overlay" // Custom class for modal overlay styling
//       >
//         <div className="modal-header">
//           <h2>Transaction Details</h2>
//           <button onClick={closeModal} className="modal-close-btn">
//             <FiX size={24} /> {/* Close icon */}
//           </button>
//         </div>
//         {selectedTransaction && (
//           <div className="modal-content">
//             <p>
//               <strong>Transaction ID:</strong> {selectedTransaction.transaction_id}
//             </p>
//             <p>
//               <strong>Amount:</strong> {selectedTransaction.amount}
//             </p>
//             <p>
//               <strong>Currency:</strong> {selectedTransaction.currency}
//             </p>
//             <p>
//               <strong>Customer Name:</strong> {selectedTransaction.cust_name}
//             </p>
//             <p>
//               <strong>Customer Email:</strong> {selectedTransaction.cust_email}
//             </p>
//             <p>
//               <strong>Customer Phone:</strong> {selectedTransaction.cust_phone}
//             </p>
//             <p>
//               <strong>City:</strong> {selectedTransaction.cust_city}
//             </p>
//             <p>
//               <strong>Membership Duration:</strong>{' '}
//               {selectedTransaction.membership_duration} months
//             </p>
//             <p>
//               <strong>Payment Type:</strong> {selectedTransaction.payment_type}
//             </p>
//             <p>
//               <strong>Status:</strong> {selectedTransaction.status}
//             </p>
//             {/* Refund and Chargeback Buttons */}
//             {selectedTransaction.status === 'SUCCESS' && (
//               <div className="modal-actions">
//                 <button className="refund-button" onClick={handleRefund}>
//                   Refund
//                 </button>
//                 <button className="chargeback-button" onClick={handleChargeback}>
//                   Chargeback
//                 </button>
//               </div>
//             )}
//           </div>
//         )}
//       </Modal>
//     </div>
//   );
// };

// export default Transactions;




// src/components/Transactions.js

import React, { useState, useEffect } from 'react';
import '../styles/Transactions.css';
import Modal from 'react-modal';
import { FiDownload, FiSearch, FiX } from 'react-icons/fi';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';

// Set the app element for accessibility
Modal.setAppElement('#root');

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [activeTab, setActiveTab] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const merchantId = localStorage.getItem('merchantId');

  // Fetch transactions from the backend API
  const fetchTransactions = async () => {
    try {
      const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
      const data = await response.json();
      if (data.status === 'Success') {
        setTransactions(data.transactions);
      } else {
        console.error('Error fetching transactions:', data.message);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  // Polling to fetch data at regular intervals
  useEffect(() => {
    fetchTransactions();
    const intervalId = setInterval(fetchTransactions, 5000);
    return () => clearInterval(intervalId);
  }, []);

  // Filter Transactions by Status, Search Term, and Date Range
  const filterTransactions = () => {
    let filtered = transactions;

    // Filter by active tab (status)
    if (activeTab !== 'All') {
      filtered = filtered.filter((txn) => txn.status.toLowerCase() === activeTab.toLowerCase());
    }

    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(
        (txn) =>
          txn.transaction_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          txn.cust_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          txn.cust_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          txn.cust_phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
          txn.payment_type.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Filter by date range
    if (startDate && endDate) {
      filtered = filtered.filter((txn) => {
        const txnDate = new Date(txn.created_at);
        return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
      });
    }

    setFilteredTransactions(filtered);
  };

  // Effect for filter changes
  useEffect(() => {
    filterTransactions();
    setCurrentPage(1);
  }, [activeTab, searchTerm, startDate, endDate]);

  // Effect for transactions data update
  useEffect(() => {
    filterTransactions();
  }, [transactions]);

  // Handle Page Change
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination Logic
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = filteredTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );
  const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

  // Open Modal with Transaction Details
  const openModal = (transaction) => {
    setSelectedTransaction(transaction);
    setModalIsOpen(true);
  };

  // Close Modal
  const closeModal = () => {
    setSelectedTransaction(null);
    setModalIsOpen(false);
  };

  // Handle Refund
  const handleRefund = async () => {
    if (!selectedTransaction) return;

    try {
      const response = await fetch('https://payin.payinfy.com/api/process-refund/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transaction_id: selectedTransaction.transaction_id,
          merchant_id: merchantId,
        }),
      });

      const data = await response.json();
      if (data.status === 'Success') {
        toast.success('Refund processed successfully');
        fetchTransactions(); // Refresh transactions
        closeModal();
      } else {
        toast.error(data.message || 'Failed to process refund');
      }
    } catch (error) {
      toast.error('An error occurred while processing refund');
    }
  };

  // Handle Chargeback
  const handleChargeback = async () => {
    if (!selectedTransaction) return;

    try {
      const response = await fetch('https://payin.payinfy.com/api/process-chargeback/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transaction_id: selectedTransaction.transaction_id,
          merchant_id: merchantId,
        }),
      });

      const data = await response.json();
      if (data.status === 'Success') {
        toast.success('Chargeback processed successfully');
        fetchTransactions(); // Refresh transactions
        closeModal();
      } else {
        toast.error(data.message || 'Failed to process chargeback');
      }
    } catch (error) {
      toast.error('An error occurred while processing chargeback');
    }
  };

  // Download CSV Data
  const csvHeaders = [
    { label: 'Date & Time', key: 'created_at' },
    { label: 'Transaction ID', key: 'transaction_id' },
    { label: 'Amount', key: 'amount' },
    { label: 'Currency', key: 'currency' },
    { label: 'Customer Name', key: 'cust_name' },
    { label: 'Customer Email', key: 'cust_email' },
    { label: 'Customer Phone', key: 'cust_phone' },
    { label: 'Payment Type', key: 'payment_type' },
    { label: 'Status', key: 'status' },
  ];

  return (
    <div className="transactions-content">
      {/* Filter and Action Bar */}
      <div className="transactions-action-bar">
        {/* Tabs for Status Filter */}
        <div className="transactions-tabs">
          {['All', 'Success', 'Pending', 'Failed'].map((tab) => (
            <button
              key={tab}
              className={`tab-button ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Search and Date Filter */}
        <div className="transactions-actions">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search transactions"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FiSearch className="search-icon" />
          </div>
          <div className="date-filter">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
            />
            <span> to </span>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
            />
          </div>
          <CSVLink
            data={filteredTransactions}
            headers={csvHeaders}
            filename="transactions.csv"
          >
            <button className="download-button">
              <FiDownload className="download-icon" /> Download CSV
            </button>
          </CSVLink>
        </div>
      </div>

      {/* Transactions Table */}
      <div className="transactions-table-container">
        <div className="transactions-table-wrapper">
          <table className="transactions-table">
            <thead>
              <tr>
                <th>Date & Time</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>Customer Name</th>
                <th>Customer Email</th>
                <th>Customer Phone</th>
                <th>Payment Type</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentTransactions.length > 0 ? (
                currentTransactions.map((txn) => (
                  <tr key={txn.transaction_id}>
                    <td>{new Date(txn.created_at).toLocaleString()}</td>
                    <td>{txn.transaction_id}</td>
                    <td>{txn.amount}</td>
                    <td>{txn.currency}</td>
                    <td>{txn.cust_name}</td>
                    <td>{txn.cust_email}</td>
                    <td>{txn.cust_phone}</td>
                    <td>{txn.payment_type}</td>
                    <td>
                      <span className={`status ${txn.status.toLowerCase()}`}>
                        {txn.status}
                      </span>
                    </td>
                    <td>
                      <button
                        className="action-button"
                        onClick={() => openModal(txn)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className="no-transactions">
                    No transactions found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination">
          {[...Array(totalPages || 1)].map((_, idx) => (
            <button
              key={idx + 1}
              className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(idx + 1)}
              disabled={filteredTransactions.length === 0}
            >
              {idx + 1}
            </button>
          ))}
        </div>
      </div>

      {/* Modal to View Transaction Details */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Transaction Details"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="modal-header">
          <h2>Transaction Details</h2>
          <button onClick={closeModal} className="modal-close-btn">
            <FiX size={24} />
          </button>
        </div>
        {selectedTransaction && (
          <div className="modal-content">
            <p>
              <strong>Transaction ID:</strong> {selectedTransaction.transaction_id}
            </p>
            <p>
              <strong>Amount:</strong> {selectedTransaction.amount}
            </p>
            <p>
              <strong>Currency:</strong> {selectedTransaction.currency}
            </p>
            <p>
              <strong>Customer Name:</strong> {selectedTransaction.cust_name}
            </p>
            <p>
              <strong>Customer Email:</strong> {selectedTransaction.cust_email}
            </p>
            <p>
              <strong>Customer Phone:</strong> {selectedTransaction.cust_phone}
            </p>
            <p>
              <strong>City:</strong> {selectedTransaction.cust_city}
            </p>
            <p>
              <strong>Membership Duration:</strong>{' '}
              {selectedTransaction.membership_duration} months
            </p>
            <p>
              <strong>Payment Type:</strong> {selectedTransaction.payment_type}
            </p>
            <p>
              <strong>Status:</strong> {selectedTransaction.status}
            </p>
            {/* Refund and Chargeback Buttons */}
            {selectedTransaction.status === 'SUCCESS' && (
              <div className="modal-actions">
                <button className="refund-button" onClick={handleRefund}>
                  Refund
                </button>
                <button className="chargeback-button" onClick={handleChargeback}>
                  Chargeback
                </button>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Transactions;
