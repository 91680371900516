// // src/components/Sidebar.js

// import React, { useState, useEffect, useRef } from 'react';
// import { NavLink, useLocation, useNavigate } from 'react-router-dom';
// import '../styles/Sidebar.css';

// const Sidebar = () => {
//   // State variables to track whether each submenu is open
//   const [isPayinOpen, setIsPayinOpen] = useState(false);
//   const [isPayoutOpen, setIsPayoutOpen] = useState(false);
//   const [isSettingsOpen, setIsSettingsOpen] = useState(false);

//   // React Router hooks
//   const location = useLocation();
//   const navigate = useNavigate();

//   // Refs for the submenus to access DOM elements directly
//   const payinRef = useRef(null);
//   const payoutRef = useRef(null);
//   const settingsRef = useRef(null);

//   // Toggles the visibility of a submenu with smooth animations.
//   const toggleSubmenu = (submenu, setIsOpen, isOpen) => {
//     setIsOpen(!isOpen);

//     if (submenu.current) {
//       if (!isOpen) {
//         // Opening the submenu: set height to the scrollHeight to expand
//         submenu.current.style.height = `${submenu.current.scrollHeight}px`;
//       } else {
//         // Closing the submenu: set height to 0 to collapse
//         submenu.current.style.height = '0';
//       }
//     }
//   };

//   // Specific toggle functions for each submenu
//   const togglePayin = () => {
//     toggleSubmenu(payinRef, setIsPayinOpen, isPayinOpen);
//   };

//   const togglePayout = () => {
//     toggleSubmenu(payoutRef, setIsPayoutOpen, isPayoutOpen);
//   };

//   const toggleSettings = () => {
//     toggleSubmenu(settingsRef, setIsSettingsOpen, isSettingsOpen);
//   };

//   // Effect hook to handle submenu state based on the current route
//   useEffect(() => {
//     // Payin submenu
//     if (location.pathname.startsWith('/payin')) {
//       setIsPayinOpen(true);
//       if (payinRef.current) {
//         payinRef.current.style.height = `${payinRef.current.scrollHeight}px`;
//       }
//     } else {
//       setIsPayinOpen(false);
//       if (payinRef.current) {
//         payinRef.current.style.height = '0';
//       }
//     }

//     // Payout submenu
//     if (location.pathname.startsWith('/payout')) {
//       setIsPayoutOpen(true);
//       if (payoutRef.current) {
//         payoutRef.current.style.height = `${payoutRef.current.scrollHeight}px`;
//       }
//     } else {
//       setIsPayoutOpen(false);
//       if (payoutRef.current) {
//         payoutRef.current.style.height = '0';
//       }
//     }

//     // Settings submenu
//     if (location.pathname.startsWith('/settings')) {
//       setIsSettingsOpen(true);
//       if (settingsRef.current) {
//         settingsRef.current.style.height = `${settingsRef.current.scrollHeight}px`;
//       }
//     } else {
//       setIsSettingsOpen(false);
//       if (settingsRef.current) {
//         settingsRef.current.style.height = '0';
//       }
//     }
//   }, [location.pathname]);

//   // Handle Logout Functionality
//   const handleLogout = () => {
//     // TODO: Implement actual logout logic, such as clearing authentication tokens or state
//     // For now, we'll just navigate to the login page
//     // Example:
//     // localStorage.removeItem('authToken');
//     // navigate('/');
//     alert('You have been logged out.');
//     navigate('/');
//   };

//   return (
//     <aside className="sidebar" aria-label="Main Navigation">
//       {/* Logo Section */}
//       <div className="logo">
//         <img src="/payinfy_logo.png" alt="Payinfy Logo" />
//       </div>

//       {/* Navigation Menu */}
//       <nav>
//         <ul>
//           {/* Dashboard Link */}
//           <li>
//             <NavLink
//               to="/dashboard"
//               className={({ isActive }) =>
//                 isActive ? 'nav-item active' : 'nav-item'
//               }
//             >
//               Dashboard
//             </NavLink>
//           </li>

//           {/* Payin Section with Expandable Submenu */}
//           <li>
//             <div
//               className={`nav-item expandable ${isPayinOpen ? 'active' : ''}`}
//               onClick={togglePayin}
//               aria-expanded={isPayinOpen}
//               aria-controls="payin-submenu"
//               aria-haspopup="true"
//               role="button"
//               tabIndex={0}
//               onKeyDown={(e) => {
//                 if (e.key === 'Enter' || e.key === ' ') {
//                   togglePayin();
//                 }
//               }}
//             >
//               <span>Payin</span>
//               <span className="chevron" aria-hidden="true">
//                 ▶
//               </span>
//             </div>
//             {/* Payin Submenu */}
//             <ul id="payin-submenu" className="submenu" ref={payinRef}>
//               <li>
//                 <NavLink
//                   to="/payin/transactions"
//                   className={({ isActive }) =>
//                     isActive ? 'submenu-item active' : 'submenu-item'
//                   }
//                 >
//                   Transactions
//                 </NavLink>
//               </li>
//               <li>
//                 <NavLink
//                   to="/payin/settlement"
//                   className={({ isActive }) =>
//                     isActive ? 'submenu-item active' : 'submenu-item'
//                   }
//                 >
//                   Settlement
//                 </NavLink>
//               </li>
//               <li>
//                 <NavLink
//                   to="/payin/rolling-reserve"
//                   className={({ isActive }) =>
//                     isActive ? 'submenu-item active' : 'submenu-item'
//                   }
//                 >
//                   Rolling Reserve
//                 </NavLink>
//               </li>
//             </ul>
//           </li>

//           {/* Payout Section with Expandable Submenu */}
//           <li>
//             <div
//               className={`nav-item expandable ${isPayoutOpen ? 'active' : ''}`}
//               onClick={togglePayout}
//               aria-expanded={isPayoutOpen}
//               aria-controls="payout-submenu"
//               aria-haspopup="true"
//               role="button"
//               tabIndex={0}
//               onKeyDown={(e) => {
//                 if (e.key === 'Enter' || e.key === ' ') {
//                   togglePayout();
//                 }
//               }}
//             >
//               <span>Payout</span>
//               <span className="chevron" aria-hidden="true">
//                 ▶
//               </span>
//             </div>
//             {/* Payout Submenu */}
//             <ul id="payout-submenu" className="submenu" ref={payoutRef}>
//               <li>
//                 <NavLink
//                   to="/payout/additional-functionality"
//                   className={({ isActive }) =>
//                     isActive ? 'submenu-item active' : 'submenu-item'
//                   }
//                 >
//                   Coming Soon....
//                 </NavLink>
//               </li>
//             </ul>
//           </li>

//           {/* Reports Link */}
//           {/* <li>
//             <NavLink
//               to="/download"
//               className={({ isActive }) =>
//                 isActive ? 'nav-item active' : 'nav-item'
//               }
//             >
//               Download
//             </NavLink>
//           </li> */}

//           {/* Settings Section with Expandable Submenu */}
//           <li>
//             <div
//               className={`nav-item expandable ${isSettingsOpen ? 'active' : ''}`}
//               onClick={toggleSettings}
//               aria-expanded={isSettingsOpen}
//               aria-controls="settings-submenu"
//               aria-haspopup="true"
//               role="button"
//               tabIndex={0}
//               onKeyDown={(e) => {
//                 if (e.key === 'Enter' || e.key === ' ') {
//                   toggleSettings();
//                 }
//               }}
//             >
//               <span>Settings</span>
//               <span className="chevron" aria-hidden="true">
//                 ▶
//               </span>
//             </div>
//             {/* Settings Submenu */}
//             <ul id="settings-submenu" className="submenu" ref={settingsRef}>
//               <li>
//                 <NavLink
//                   to="/settings/profile"
//                   className={({ isActive }) =>
//                     isActive ? 'submenu-item active' : 'submenu-item'
//                   }
//                 >
//                   Profile
//                 </NavLink>
//               </li>
//               {/* <li>
//                 <NavLink
//                   to="/settings/users"
//                   className={({ isActive }) =>
//                     isActive ? 'submenu-item active' : 'submenu-item'
//                   }
//                 >
//                   Users
//                 </NavLink>
//               </li> */}
//             </ul>
//           </li>

//           {/* Logout Button */}
//           <li>
//             <button className="nav-item logout-button" onClick={handleLogout}>
//               Logout
//             </button>
//           </li>
//         </ul>
//       </nav>
//     </aside>
//   );
// };

// export default Sidebar;


// src/components/Sidebar.js

// import React, { useState, useEffect, useRef } from 'react';
// import { NavLink, useLocation, useNavigate } from 'react-router-dom';
// import '../styles/Sidebar.css';

// const Sidebar = () => {
//   // State variables to track whether each submenu is open
//   const [isPayinOpen, setIsPayinOpen] = useState(false);
//   const [isPayoutOpen, setIsPayoutOpen] = useState(false);
//   const [isSettingsOpen, setIsSettingsOpen] = useState(false);

//   // React Router hooks
//   const location = useLocation();
//   const navigate = useNavigate();

//   // Refs for the submenus to access DOM elements directly
//   const payinRef = useRef(null);
//   const payoutRef = useRef(null);
//   const settingsRef = useRef(null);

//   // Toggles the visibility of a submenu with smooth animations.
//   const toggleSubmenu = (submenu, setIsOpen, isOpen) => {
//     setIsOpen(!isOpen);

//     if (submenu.current) {
//       if (!isOpen) {
//         submenu.current.style.height = `${submenu.current.scrollHeight}px`;
//       } else {
//         submenu.current.style.height = '0';
//       }
//     }
//   };

//   // Specific toggle functions for each submenu
//   const togglePayin = () => {
//     toggleSubmenu(payinRef, setIsPayinOpen, isPayinOpen);
//   };

//   const togglePayout = () => {
//     toggleSubmenu(payoutRef, setIsPayoutOpen, isPayoutOpen);
//   };

//   const toggleSettings = () => {
//     toggleSubmenu(settingsRef, setIsSettingsOpen, isSettingsOpen);
//   };

//   // Effect hook to handle submenu state based on the current route
//   useEffect(() => {
//     if (location.pathname.startsWith('/payin')) {
//       setIsPayinOpen(true);
//       if (payinRef.current) {
//         payinRef.current.style.height = `${payinRef.current.scrollHeight}px`;
//       }
//     } else {
//       setIsPayinOpen(false);
//       if (payinRef.current) {
//         payinRef.current.style.height = '0';
//       }
//     }

//     if (location.pathname.startsWith('/payout')) {
//       setIsPayoutOpen(true);
//       if (payoutRef.current) {
//         payoutRef.current.style.height = `${payoutRef.current.scrollHeight}px`;
//       }
//     } else {
//       setIsPayoutOpen(false);
//       if (payoutRef.current) {
//         payoutRef.current.style.height = '0';
//       }
//     }

//     if (location.pathname.startsWith('/settings')) {
//       setIsSettingsOpen(true);
//       if (settingsRef.current) {
//         settingsRef.current.style.height = `${settingsRef.current.scrollHeight}px`;
//       }
//     } else {
//       setIsSettingsOpen(false);
//       if (settingsRef.current) {
//         settingsRef.current.style.height = '0';
//       }
//     }
//   }, [location.pathname]);

//   // Handle Logout Functionality
//   const handleLogout = () => {
//     alert('You have been logged out.');
//     navigate('/');
//   };

//   return (
//     <aside className="sidebar" aria-label="Main Navigation">
//       {/* Logo Section */}
//       <div className="logo">
//         <img src="/payinfy_logo.png" alt="Payinfy Logo" />
//       </div>

//       {/* Navigation Menu */}
//       <nav>
//         <ul>
//           {/* Dashboard Link */}
//           <li>
//             <NavLink
//               to="/dashboard"
//               className={({ isActive }) =>
//                 isActive ? 'nav-item active' : 'nav-item'
//               }
//             >
//               Dashboard
//             </NavLink>
//           </li>

//           {/* Payin Section with Expandable Submenu */}
//           <li>
//             <div
//               className={`nav-item expandable ${isPayinOpen ? 'active' : ''}`}
//               onClick={togglePayin}
//               aria-expanded={isPayinOpen}
//               aria-controls="payin-submenu"
//               aria-haspopup="true"
//               role="button"
//               tabIndex={0}
//               onKeyDown={(e) => {
//                 if (e.key === 'Enter' || e.key === ' ') {
//                   togglePayin();
//                 }
//               }}
//             >
//               <span>Deposits</span>
//               <span className="chevron" aria-hidden="true">
//                 ▶
//               </span>
//             </div>
//             {/* Payin Submenu */}
//             <ul id="payin-submenu" className="submenu" ref={payinRef}>
//               <li>
//                 <NavLink
//                   to="/payin/transactions"
//                   className={({ isActive }) =>
//                     isActive ? 'submenu-item active' : 'submenu-item'
//                   }
//                 >
//                   Transactions
//                 </NavLink>
//               </li>
//             </ul>
//           </li>

//           {/* Settlement Link */}
//           <li>
//             <NavLink
//               to="/payin/settlement"
//               className={({ isActive }) =>
//                 isActive ? 'nav-item active' : 'nav-item'
//               }
//             >
//               Settlement
//             </NavLink>
//           </li>

//           {/* Rolling Reserve Link */}
//           <li>
//             <NavLink
//               to="/payin/rolling-reserve"
//               className={({ isActive }) =>
//                 isActive ? 'nav-item active' : 'nav-item'
//               }
//             >
//               Rolling Reserve
//             </NavLink>
//           </li>

//           {/* Payout Section with Expandable Submenu */}
//           <li>
//             <div
//               className={`nav-item expandable ${isPayoutOpen ? 'active' : ''}`}
//               onClick={togglePayout}
//               aria-expanded={isPayoutOpen}
//               aria-controls="payout-submenu"
//               aria-haspopup="true"
//               role="button"
//               tabIndex={0}
//               onKeyDown={(e) => {
//                 if (e.key === 'Enter' || e.key === ' ') {
//                   togglePayout();
//                 }
//               }}
//             >
//               <span>Payout</span>
//               <span className="chevron" aria-hidden="true">
//                 ▶
//               </span>
//             </div>
//             {/* Payout Submenu */}
//             <ul id="payout-submenu" className="submenu" ref={payoutRef}>
//               <li>
//                 <NavLink
//                   to="/payout/additional-functionality"
//                   className={({ isActive }) =>
//                     isActive ? 'submenu-item active' : 'submenu-item'
//                   }
//                 >
//                   Coming Soon...
//                 </NavLink>
//               </li>
//             </ul>
//           </li>

//           {/* Settings Section with Expandable Submenu */}
//           <li>
//             <div
//               className={`nav-item expandable ${isSettingsOpen ? 'active' : ''}`}
//               onClick={toggleSettings}
//               aria-expanded={isSettingsOpen}
//               aria-controls="settings-submenu"
//               aria-haspopup="true"
//               role="button"
//               tabIndex={0}
//               onKeyDown={(e) => {
//                 if (e.key === 'Enter' || e.key === ' ') {
//                   toggleSettings();
//                 }
//               }}
//             >
//               <span>Settings</span>
//               <span className="chevron" aria-hidden="true">
//                 ▶
//               </span>
//             </div>
//             {/* Settings Submenu */}
//             <ul id="settings-submenu" className="submenu" ref={settingsRef}>
//               <li>
//                 <NavLink
//                   to="/settings/profile"
//                   className={({ isActive }) =>
//                     isActive ? 'submenu-item active' : 'submenu-item'
//                   }
//                 >
//                   Profile
//                 </NavLink>
//               </li>
//             </ul>
//           </li>

//           {/* Logout Button */}
//           <li>
//             <button className="nav-item logout-button" onClick={handleLogout}>
//               Logout
//             </button>
//           </li>
//         </ul>
//       </nav>
//     </aside>
//   );
// };

// export default Sidebar;


import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import '../styles/Sidebar.css';

const Sidebar = () => {
  // State variables to track whether each submenu is open
  const [isPayinOpen, setIsPayinOpen] = useState(false);
  const [isPayoutOpen, setIsPayoutOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  // React Router hooks
  const location = useLocation();
  const navigate = useNavigate();

  // Refs for the submenus to access DOM elements directly
  const payinRef = useRef(null);
  const payoutRef = useRef(null);
  const settingsRef = useRef(null);

  // Toggles the visibility of a submenu with smooth animations.
  const toggleSubmenu = (submenu, setIsOpen, isOpen) => {
    setIsOpen(!isOpen);

    if (submenu.current) {
      if (!isOpen) {
        submenu.current.style.height = `${submenu.current.scrollHeight}px`;
      } else {
        submenu.current.style.height = '0';
      }
    }
  };

  // Specific toggle functions for each submenu
  const togglePayin = () => {
    toggleSubmenu(payinRef, setIsPayinOpen, isPayinOpen);
  };

  const togglePayout = () => {
    toggleSubmenu(payoutRef, setIsPayoutOpen, isPayoutOpen);
  };

  const toggleSettings = () => {
    toggleSubmenu(settingsRef, setIsSettingsOpen, isSettingsOpen);
  };

  // Effect hook to handle submenu state based on the current route
  useEffect(() => {
    if (location.pathname.startsWith('/payin')) {
      setIsPayinOpen(true);
      if (payinRef.current) {
        payinRef.current.style.height = `${payinRef.current.scrollHeight}px`;
      }
    } else {
      setIsPayinOpen(false);
      if (payinRef.current) {
        payinRef.current.style.height = '0';
      }
    }

    if (location.pathname.startsWith('/payout')) {
      setIsPayoutOpen(true);
      if (payoutRef.current) {
        payoutRef.current.style.height = `${payoutRef.current.scrollHeight}px`;
      }
    } else {
      setIsPayoutOpen(false);
      if (payoutRef.current) {
        payoutRef.current.style.height = '0';
      }
    }

    if (location.pathname.startsWith('/settings')) {
      setIsSettingsOpen(true);
      if (settingsRef.current) {
        settingsRef.current.style.height = `${settingsRef.current.scrollHeight}px`;
      }
    } else {
      setIsSettingsOpen(false);
      if (settingsRef.current) {
        settingsRef.current.style.height = '0';
      }
    }
  }, [location.pathname]);

  // Handle Logout Functionality
  const handleLogout = () => {
    alert('You have been logged out.');
    navigate('/');
  };

  return (
    <aside className="sidebar" aria-label="Main Navigation">
      {/* Logo Section */}
      <div className="logo">
        <img src="/payinfy_logo.png" alt="Payinfy Logo" />
      </div>

      {/* Navigation Menu */}
      <nav>
        <ul>
          {/* Dashboard Link */}
          <li>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                isActive ? 'nav-item active' : 'nav-item'
              }
            >
              Dashboard
            </NavLink>
          </li>

          {/* Payin Section with Expandable Submenu */}
          <li>
            <div
              className={`nav-item expandable ${isPayinOpen ? 'active' : ''}`}
              onClick={togglePayin}
              aria-expanded={isPayinOpen}
              aria-controls="payin-submenu"
              aria-haspopup="true"
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  togglePayin();
                }
              }}
            >
              <span>Deposits</span>
              <span className="chevron" aria-hidden="true">
                ▶
              </span>
            </div>
            {/* Payin Submenu */}
            <ul id="payin-submenu" className="submenu" ref={payinRef}>
              <li>
                <NavLink
                  to="/payin/transactions"
                  className={({ isActive }) =>
                    isActive ? 'submenu-item active' : 'submenu-item'
                  }
                >
                  Transactions
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Settlement Link */}
          <li>
            <NavLink
              to="/payin/settlement"
              className={({ isActive }) =>
                isActive ? 'nav-item active' : 'nav-item'
              }
            >
              Settlement
            </NavLink>
          </li>

          {/* Rolling Reserve Link */}
          <li>
            <NavLink
              to="/payin/rolling-reserve"
              className={({ isActive }) =>
                isActive ? 'nav-item active' : 'nav-item'
              }
            >
              Rolling Reserve
            </NavLink>
          </li>

          {/* Refunds Link */}
          <li>
            <NavLink
              to="/refunds"
              className={({ isActive }) =>
                isActive ? 'nav-item active' : 'nav-item'
              }
            >
              Refunds
            </NavLink>
          </li>

          {/* Chargebacks Link */}
          <li>
            <NavLink
              to="/chargebacks"
              className={({ isActive }) =>
                isActive ? 'nav-item active' : 'nav-item'
              }
            >
              Chargebacks
            </NavLink>
          </li>

          {/* Payout Section with Expandable Submenu */}
          <li>
            <div
              className={`nav-item expandable ${isPayoutOpen ? 'active' : ''}`}
              onClick={togglePayout}
              aria-expanded={isPayoutOpen}
              aria-controls="payout-submenu"
              aria-haspopup="true"
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  togglePayout();
                }
              }}
            >
              <span>Payout</span>
              <span className="chevron" aria-hidden="true">
                ▶
              </span>
            </div>
            {/* Payout Submenu */}
            <ul id="payout-submenu" className="submenu" ref={payoutRef}>
              <li>
                <NavLink
                  to="/payout/additional-functionality"
                  className={({ isActive }) =>
                    isActive ? 'submenu-item active' : 'submenu-item'
                  }
                >
                  Coming Soon...
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Settings Section with Expandable Submenu */}
          <li>
            <div
              className={`nav-item expandable ${isSettingsOpen ? 'active' : ''}`}
              onClick={toggleSettings}
              aria-expanded={isSettingsOpen}
              aria-controls="settings-submenu"
              aria-haspopup="true"
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  toggleSettings();
                }
              }}
            >
              <span>Settings</span>
              <span className="chevron" aria-hidden="true">
                ▶
              </span>
            </div>
            {/* Settings Submenu */}
            <ul id="settings-submenu" className="submenu" ref={settingsRef}>
              <li>
                <NavLink
                  to="/settings/profile"
                  className={({ isActive }) =>
                    isActive ? 'submenu-item active' : 'submenu-item'
                  }
                >
                  Profile
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Logout Button */}
          <li>
            <button className="nav-item logout-button" onClick={handleLogout}>
              Logout
            </button>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
