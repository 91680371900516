// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';

// const Profile = () => {
//   // **State Variables for Profile Fields**
//   const [profileData, setProfileData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlement: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//   });

//   /**
//    * useEffect Hook
//    *
//    * This hook runs when the component mounts.
//    * Backend Developer: Replace the code inside this hook with an API call to fetch profile data.
//    */
//   useEffect(() => {
//     // TODO: Fetch profile data from the backend API and update the 'profileData' state.
//     // Example:
//     // fetch('/api/profile')
//     //   .then(response => response.json())
//     //   .then(data => {
//     //     setProfileData(data);
//     //   })
//     //   .catch(error => {
//     //     console.error('Error fetching profile data:', error);
//     //     // Handle error state if needed
//     //   });

//     // Simulate fetching data with placeholder values
//     const simulatedData = {
//       firstName: 'John',
//       lastName: 'Doe',
//       email: 'john.doe@example.com',
//       mid: 'MID123456',
//       salt: 'abc123salt',
//       settlement: 'T + 2 days',
//       payoutSecretKey: 'payout_secret_key',
//       payoutCallbackURL: 'https://example.com/payout-callback',
//       payinSecretKey: 'payin_secret_key',
//       payinCallbackURL: 'https://example.com/payin-callback',
//     };

//     setProfileData(simulatedData);
//   }, []);

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="firstName">First Name:</label>
//             <input
//               type="text"
//               id="firstName"
//               name="firstName"
//               value={profileData.firstName}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="lastName">Last Name:</label>
//             <input
//               type="text"
//               id="lastName"
//               name="lastName"
//               value={profileData.lastName}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={profileData.email}
//               readOnly
//             />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input
//               type="text"
//               id="mid"
//               name="mid"
//               value={profileData.mid}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input
//               type="text"
//               id="salt"
//               name="salt"
//               value={profileData.salt}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="settlement">Settlement (T + n days):</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={profileData.settlement}
//               readOnly
//             />
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <input
//               type="text"
//               id="payinCallbackURL"
//               name="payinCallbackURL"
//               value={profileData.payinCallbackURL}
//               readOnly
//             />
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';

// const Profile = () => {
//   // **State Variables for Profile Fields**
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//   });

//   /**
//    * useEffect Hook
//    *
//    * This hook runs when the component mounts and fetches data from localStorage
//    * (which was set during the login process).
//    */
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: localStorage.getItem('settlement_period') || '', // Settlement period from login response
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input
//               type="text"
//               id="name"
//               name="name"
//               value={profileData.name}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={profileData.email}
//               readOnly
//             />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input
//               type="text"
//               id="mid"
//               name="mid"
//               value={profileData.mid}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input
//               type="text"
//               id="salt"
//               name="salt"
//               value={profileData.salt}
//               readOnly
//             />
//           </div>

//           {/* Updated Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={profileData.settlementPeriod ? `T + ${profileData.settlementPeriod} days` : 'N/A'} // Correctly format settlement period
//               readOnly
//             />
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <input
//               type="text"
//               id="payinCallbackURL"
//               name="payinCallbackURL"
//               value={profileData.payinCallbackURL}
//               readOnly
//             />
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';

// const Profile = () => {
//   // **State Variables for Profile Fields**
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//   });

//   /**
//    * useEffect Hook
//    *
//    * This hook runs when the component mounts and fetches data from localStorage
//    * (which was set during the login process).
//    */
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period');  // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod || '', // Retrieve settlement period
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input
//               type="text"
//               id="name"
//               name="name"
//               value={profileData.name}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={profileData.email}
//               readOnly
//             />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input
//               type="text"
//               id="mid"
//               name="mid"
//               value={profileData.mid}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input
//               type="text"
//               id="salt"
//               name="salt"
//               value={profileData.salt}
//               readOnly
//             />
//           </div>

//           {/* Updated Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={profileData.settlementPeriod ? `T + ${profileData.settlementPeriod} days` : 'N/A'}  // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <input
//               type="text"
//               id="payinCallbackURL"
//               name="payinCallbackURL"
//               value={profileData.payinCallbackURL}
//               readOnly
//             />
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa'; // Importing icons

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // useEffect Hook to fetch data from localStorage
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period'); // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod || '', // Retrieve settlement period
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   // Function to handle clicking the edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the modal
//   const handleCloseModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleCloseModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               } // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <input
//               type="text"
//               id="payinCallbackURL"
//               name="payinCallbackURL"
//               value={profileData.payinCallbackURL}
//               readOnly
//             />
//           </div>
//         </form>
//       </div>

//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;

// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa'; // Importing icons

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // useEffect Hook to fetch data from localStorage
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period'); // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod || '', // Retrieve settlement period
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-payin-callback-url/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               } // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa'; // Importing icons

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // useEffect Hook to fetch data from localStorage
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period'); // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod !== null ? settlementPeriod : '', // Retrieve settlement period
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-payin-callback-url/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               } // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// File: src/components/Profile.jsx

// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa'; // Importing icons

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // useEffect Hook to fetch data from localStorage
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period'); // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod !== null ? settlementPeriod : '', // Retrieve settlement period
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-payin-callback-url/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               } // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa'; // Importing icons

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//     successRedirectURL: '', // Added for success redirection URL
//     failureRedirectURL: '', // Added for failure redirection URL
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // useEffect Hook to fetch data from localStorage
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period'); // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod !== null ? settlementPeriod : '', // Retrieve settlement period
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//       successRedirectURL: localStorage.getItem('successRedirectURL') || '', // Added
//       failureRedirectURL: localStorage.getItem('failureRedirectURL') || '', // Added
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           success_redirect_url: newSuccessRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           failure_redirect_url: newFailureRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               } // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



import React, { useState, useEffect } from 'react';
import '../styles/Profile.css';
import { FaEdit, FaTimes } from 'react-icons/fa'; // Importing icons

const Profile = () => {
  // State Variables for Profile Fields
  const [profileData, setProfileData] = useState({
    name: '', // Full name of the user
    email: '',
    mid: '',
    salt: '',
    settlementPeriod: '', // This will store the settlement period value
    refundFee: '',         // Added refundFee state variable
    chargebackFee: '',     // Added chargebackFee state variable
    payoutSecretKey: '',
    payoutCallbackURL: '',
    payinSecretKey: '', // PayIn Secret Key to be populated from login response
    payinCallbackURL: '',
    successRedirectURL: '', // Added for success redirection URL
    failureRedirectURL: '', // Added for failure redirection URL
  });

  // State variables for password change modal
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  // State variables for PayIn Callback URL modal
  const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
  const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
  const [payinCallbackError, setPayinCallbackError] = useState('');

  // State variables for Success Redirect URL modal
  const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
  const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
  const [successRedirectError, setSuccessRedirectError] = useState('');

  // State variables for Failure Redirect URL modal
  const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
  const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
  const [failureRedirectError, setFailureRedirectError] = useState('');

  // useEffect Hook to fetch data from localStorage
  useEffect(() => {
    // Retrieve profile data from localStorage
    const settlementPeriod = localStorage.getItem('settlement_period'); // Fetch settlement period
    const storedProfileData = {
      name: localStorage.getItem('merchantName') || '', // Full name from login response
      email: localStorage.getItem('merchantEmail') || '',
      mid: localStorage.getItem('merchantId') || '',
      salt: localStorage.getItem('salt') || '', // Salt from login response
      settlementPeriod: settlementPeriod !== null ? settlementPeriod : '', // Retrieve settlement period
      refundFee: localStorage.getItem('refundFee') || '', // Fetch refundFee from localStorage
      chargebackFee: localStorage.getItem('chargebackFee') || '', // Fetch chargebackFee from localStorage
      payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
      payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
      payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
      payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
      successRedirectURL: localStorage.getItem('successRedirectURL') || '', // Added
      failureRedirectURL: localStorage.getItem('failureRedirectURL') || '', // Added
    };

    setProfileData(storedProfileData);
  }, []);

  // Function to handle clicking the password edit icon
  const handlePasswordChangeClick = () => {
    setShowPasswordModal(true);
  };

  // Function to handle closing the password modal
  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setPasswordError('');
  };

  // Function to submit the password change
  const handleSubmitPasswordChange = async () => {
    if (newPassword !== confirmNewPassword) {
      setPasswordError('New passwords do not match');
      return;
    }

    try {
      const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
        }),
      });

      if (response.ok) {
        alert('Password changed successfully');
        handleClosePasswordModal();
      } else {
        const result = await response.json();
        setPasswordError(result.message || 'Failed to change password');
      }
    } catch (error) {
      setPasswordError('An error occurred');
    }
  };

  // Function to handle clicking the PayIn Callback URL edit icon
  const handlePayinCallbackEditClick = () => {
    setShowPayinCallbackModal(true);
    setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
  };

  // Function to handle closing the PayIn Callback URL modal
  const handleClosePayinCallbackModal = () => {
    setShowPayinCallbackModal(false);
    setNewPayinCallbackURL('');
    setPayinCallbackError('');
  };

  // Function to submit the PayIn Callback URL change
  const handleSubmitPayinCallbackChange = async () => {
    try {
      const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payin_callback_url: newPayinCallbackURL,
        }),
      });

      if (response.ok) {
        alert('PayIn Callback URL updated successfully');
        // Update the local state and localStorage
        setProfileData((prevData) => ({
          ...prevData,
          payinCallbackURL: newPayinCallbackURL,
        }));
        localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
        handleClosePayinCallbackModal();
      } else {
        const result = await response.json();
        setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
      }
    } catch (error) {
      setPayinCallbackError('An error occurred');
    }
  };

  // Function to handle clicking the Success Redirect URL edit icon
  const handleSuccessRedirectEditClick = () => {
    setShowSuccessRedirectModal(true);
    setNewSuccessRedirectURL(profileData.successRedirectURL); // Initialize with current value
  };

  // Function to handle closing the Success Redirect URL modal
  const handleCloseSuccessRedirectModal = () => {
    setShowSuccessRedirectModal(false);
    setNewSuccessRedirectURL('');
    setSuccessRedirectError('');
  };

  // Function to submit the Success Redirect URL change
  const handleSubmitSuccessRedirectChange = async () => {
    try {
      const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          success_redirect_url: newSuccessRedirectURL,
        }),
      });

      if (response.ok) {
        alert('Success Redirect URL updated successfully');
        // Update the local state and localStorage
        setProfileData((prevData) => ({
          ...prevData,
          successRedirectURL: newSuccessRedirectURL,
        }));
        localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
        handleCloseSuccessRedirectModal();
      } else {
        const result = await response.json();
        setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
      }
    } catch (error) {
      setSuccessRedirectError('An error occurred');
    }
  };

  // Function to handle clicking the Failure Redirect URL edit icon
  const handleFailureRedirectEditClick = () => {
    setShowFailureRedirectModal(true);
    setNewFailureRedirectURL(profileData.failureRedirectURL); // Initialize with current value
  };

  // Function to handle closing the Failure Redirect URL modal
  const handleCloseFailureRedirectModal = () => {
    setShowFailureRedirectModal(false);
    setNewFailureRedirectURL('');
    setFailureRedirectError('');
  };

  // Function to submit the Failure Redirect URL change
  const handleSubmitFailureRedirectChange = async () => {
    try {
      const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          failure_redirect_url: newFailureRedirectURL,
        }),
      });

      if (response.ok) {
        alert('Failure Redirect URL updated successfully');
        // Update the local state and localStorage
        setProfileData((prevData) => ({
          ...prevData,
          failureRedirectURL: newFailureRedirectURL,
        }));
        localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
        handleCloseFailureRedirectModal();
      } else {
        const result = await response.json();
        setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
      }
    } catch (error) {
      setFailureRedirectError('An error occurred');
    }
  };

  return (
    <div className="profile-content">
      {/* Header Section */}
      <header className="profile-header">
        <div className="profile-title">
          <h2>Profile</h2>
        </div>
      </header>

      {/* Profile Information */}
      <div className="profile-info-container">
        <form className="profile-form">
          {/* Personal Information Section */}
          <h3 className="section-heading">Personal Information</h3>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" value={profileData.name} readOnly />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={profileData.email} readOnly />
          </div>

          {/* Merchant Information Section */}
          <h3 className="section-heading">Merchant Information</h3>
          <div className="form-group">
            <label htmlFor="mid">MID:</label>
            <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
          </div>

          <div className="form-group">
            <label htmlFor="salt">Salt:</label>
            <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
          </div>

          {/* Settlement Period Section */}
          <div className="form-group">
            <label htmlFor="settlement">Settlement Period:</label>
            <input
              type="text"
              id="settlement"
              name="settlement"
              value={
                profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
              } // Display settlement period
              readOnly
            />
          </div>

          {/* Refund Fee Field */}
          <div className="form-group">
            <label htmlFor="refundFee">Refund Fee:</label>
            <input
              type="text"
              id="refundFee"
              name="refundFee"
              value={
                profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'
              }
              readOnly
            />
          </div>

          {/* Chargeback Fee Field */}
          <div className="form-group">
            <label htmlFor="chargebackFee">Chargeback Fee:</label>
            <input
              type="text"
              id="chargebackFee"
              name="chargebackFee"
              value={
                profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'
              }
              readOnly
            />
          </div>

          {/* Password Field with Edit Icon */}
          <div className="form-group password-group">
            <label htmlFor="password">Password:</label>
            <div className="password-field">
              <input type="password" id="password" name="password" value="********" readOnly />
              <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
            </div>
          </div>

          {/* API Credentials Section */}
          <h3 className="section-heading">API Credentials</h3>
          {/* PayOut Secret Key and Callback URL */}
          <div className="form-group">
            <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
            <input
              type="text"
              id="payoutSecretKey"
              name="payoutSecretKey"
              value={profileData.payoutSecretKey}
              readOnly
            />
          </div>

          <div className="form-group">
            <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
            <input
              type="text"
              id="payoutCallbackURL"
              name="payoutCallbackURL"
              value={profileData.payoutCallbackURL}
              readOnly
            />
          </div>

          {/* PayIn Secret Key and Callback URL */}
          <div className="form-group">
            <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
            <input
              type="text"
              id="payinSecretKey"
              name="payinSecretKey"
              value={profileData.payinSecretKey}
              readOnly
            />
          </div>

          <div className="form-group callback-url-group">
            <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
            <div className="callback-url-field">
              <input
                type="text"
                id="payinCallbackURL"
                name="payinCallbackURL"
                value={profileData.payinCallbackURL}
                readOnly
              />
              <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
            </div>
          </div>

          {/* Success Redirect URL Field with Edit Icon */}
          <div className="form-group callback-url-group">
            <label htmlFor="successRedirectURL">Success Redirect URL:</label>
            <div className="callback-url-field">
              <input
                type="text"
                id="successRedirectURL"
                name="successRedirectURL"
                value={profileData.successRedirectURL}
                readOnly
              />
              <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
            </div>
          </div>

          {/* Failure Redirect URL Field with Edit Icon */}
          <div className="form-group callback-url-group">
            <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
            <div className="callback-url-field">
              <input
                type="text"
                id="failureRedirectURL"
                name="failureRedirectURL"
                value={profileData.failureRedirectURL}
                readOnly
              />
              <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
            </div>
          </div>
        </form>
      </div>

      {/* Password Change Modal */}
      {showPasswordModal && (
        <div className="modal">
          <div className="modal-content">
            <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
            <h3>Change Password</h3>
            <div className="form-group">
              <label htmlFor="oldPassword">Old Password:</label>
              <input
                type="password"
                id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">New Password:</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmNewPassword">Confirm New Password:</label>
              <input
                type="password"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>
            {passwordError && <p className="error-message">{passwordError}</p>}
            <button className="submit-btn" onClick={handleSubmitPasswordChange}>
              Change Password
            </button>
          </div>
        </div>
      )}

      {/* PayIn Callback URL Edit Modal */}
      {showPayinCallbackModal && (
        <div className="modal">
          <div className="modal-content">
            <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
            <h3>Edit PayIn Callback URL</h3>
            <div className="form-group">
              <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
              <input
                type="text"
                id="newPayinCallbackURL"
                value={newPayinCallbackURL}
                onChange={(e) => setNewPayinCallbackURL(e.target.value)}
              />
            </div>
            {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
            <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
              Update Callback URL
            </button>
          </div>
        </div>
      )}

      {/* Success Redirect URL Edit Modal */}
      {showSuccessRedirectModal && (
        <div className="modal">
          <div className="modal-content">
            <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
            <h3>Edit Success Redirect URL</h3>
            <div className="form-group">
              <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
              <input
                type="text"
                id="newSuccessRedirectURL"
                value={newSuccessRedirectURL}
                onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
              />
            </div>
            {successRedirectError && <p className="error-message">{successRedirectError}</p>}
            <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
              Update Success Redirect URL
            </button>
          </div>
        </div>
      )}

      {/* Failure Redirect URL Edit Modal */}
      {showFailureRedirectModal && (
        <div className="modal">
          <div className="modal-content">
            <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
            <h3>Edit Failure Redirect URL</h3>
            <div className="form-group">
              <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
              <input
                type="text"
                id="newFailureRedirectURL"
                value={newFailureRedirectURL}
                onChange={(e) => setNewFailureRedirectURL(e.target.value)}
              />
            </div>
            {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
            <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
              Update Failure Redirect URL
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
